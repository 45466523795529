import {callUrl} from "./api";

// TODO très facilement factorisable avec client.js. voir si génération auto de nos interfaces admin ?
export default {
    async getAgences(data = {}) {
        try {
            let res = await callUrl("GET", 'api/agences', data)
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getAgence(agenceId) {
        try {
            let res = await callUrl("GET", `api/agences/${agenceId}`)
            return res;
        } catch(e) {
            return null;
        }
    },

    async patchAgence(agenceId, data) {
        return await callUrl("PATCH", `api/agences/${agenceId}`, data);
    },

    async deleteAgence(agenceId) {
        return await callUrl("DELETE", `api/agences/${agenceId}`);
    },

    async addAgence(data) {
        return await callUrl("POST", `api/agences`, data);
    }
}