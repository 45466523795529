import agenceApi from "@/api/agenceApi"

export async function confirmDeleteAgence(agenceId, agenceNom, deleteCallback = null) {
    let {isConfirmed} = await this.$swal({
        title: 'Supprimer Agence',
        text: `Etes-vous sûr de vouloir supprimer le agence ${agenceNom}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmer',
        cancelButtonText: "Annuler",
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
    })

    if(isConfirmed)
        await this.deleteAgence(agenceId, deleteCallback);
}

export async function deleteAgence(agenceId, deleteCallback = null) {
    if(this.hasOwnProperty("deleting"))
        this.deleting = true;
    try {
        await agenceApi.deleteAgence(agenceId);
        this.showToast("success", "Agence supprimé", "CheckIcon")
        if(deleteCallback != null && typeof deleteCallback === "function")
            deleteCallback(agenceId);
        
    } catch(e) {
        console.log("e", e)
        this.showToast("danger", "Une erreur s'est produite", "AlertCircleIcon")
    }
    finally {
        if(this.hasOwnProperty("deleting"))
            this.deleting = false;
    }
}